.linkSection {
  --heading-font-weight: 600;
  --heading-border-bottom-style: solid;
  --heading-border-bottom-width: 1px;
  --heading-border-bottom: var(--footer-link-section-border);
  --heading-margin-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 0;

  @media (--screen-sm) {
    flex: 1 1 0;

    --heading-border-bottom: 0;
  }
}

.icon {
  color: var(--footer-link-section-chevron-icon-color);
  display: flex;
  align-items: center;
  font-size: var(--font-size-lg);
}

@media (--screen-sm) {
  .icon {
    display: none;
  }
}

.collapse {
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  font-family: var(--font-family-body);
  font-size: var(--font-size-body);
  display: flex;
  padding: var(--space-4x);
  text-align: var(--footer-list-text-align, center);
}

@media (--screen-sm) {
  .collapse {
    display: block;
    padding: 0;
  }
}

.headingLabel {
  color: var(--footer-heading-label-color);
}

.list {
  list-style: none;
  margin-left: var(--space-4x);
  text-align: left;
  line-height: var(--leading-body);
  padding: 0;
}

@media (--screen-sm) {
  .list {
    margin-left: 0;
    text-align: var(--footer-list-text-align, center);
    display: block;
  }
}

.listHidden {
  display: none;
}

.listItem {
  padding-top: var(--space-4x);
  padding-bottom: var(--space-4x);
}

@media (--screen-sm) {
  .listItem {
    padding-top: var(--space-3x);
    padding-bottom: var(--space-none);
  }
}

.listLinkBase {
  text-decoration: inherit;
  font-weight: 600;
}
