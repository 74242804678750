/*
@contract footer {
  margin: Margin
  background: BackgroundColor
  color: Color
  heading-label-color: Color
  logo-color: Color
  social-icon {
    background: BackgroundColor
    background-hover: BackgroundColor
    color: Color
    size: Height
  }
  anchor-text {
    color: Color
    color-hover: Color
    color-active: Color
  }
  link-section {
    chevron-icon {
      color: Color
    }
  }
}
*/

.footer {
  --heading-with-heading-font-font-weight: bold;
  --anchor-text-color-default: var(--footer-anchor-text-color);
  --anchor-text-color-hover: var(--footer-anchor-text-color-hover);
  --anchor-text-color-active: var(--footer-anchor-text-color-active);
  --logo-color: var(--color-white);
  background-color: var(--footer-background);
  color: var(--footer-color);
  text-align: center;
  margin: var(--footer-margin);
  position: relative;
  padding-bottom: var(--footer-padding-bottom);
}

.footerWrapper {
  max-width: 615px;
  margin-left: auto;
  margin-right: auto;
  padding: var(--space-10x) var(--space-6x);
  display: flex;
  gap: var(--space-8x);
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-body);
}

.footerInnerWrapper {
  max-width: 448px;
  margin: var(--space-none) auto;
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  font-size: var(--font-size-sm);

  @media (--screen-sm) {
    padding: var(--space-none);
  }
}

.footerLogoWrap {
  position: relative;
  width: 100%;
}

.footerLogo {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: var(--footer-logo-color);
  max-width: var(--footer-logo-max-width);
  height: 40px;
}

.footerLinks {
  @media (--screen-sm) {
    display: flex;
    justify-content: center;
  }
}

.footerApps {
  margin-bottom: var(--space-3x);
}

.footerCopyright {
  max-width: 280px;
  margin-left: auto;
  font-size: var(--font-size-xs);
  margin-right: auto;
  margin-bottom: var(--space-3x);
}

.footerDisclaimer {
  margin-bottom: var(--space-5x);
}

.footerEqualHousing {
  display: flex;
  justify-content: center;
  gap: var(--space-4x);
}

.footerEqualHousingLogoLight {
  fill: var(--color-black);
}

.footerEqualHousingLogoDark {
  fill: var(--color-white);
}

.footerEqualHousingLogo {
  max-width: 34px;
}

.footerEqualHousingCopy {
  text-align: left;
  font-size: var(--font-size-xs);
}
